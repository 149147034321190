*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
.mycontainer{
    width: 320px;
    /* height: 500px; */
    background: #fff;
    box-shadow: 0 10px 15px rgba(179, 179, 179, 0.7);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 1rem;
}

.mycontainer h2{
    color: #0A66C3;
    font-size: 1.6rem;
}

.mycontainer h2 i{
    font-size: 1.8rem;
    padding-left: 0.1rem;
}
.intro-text p{
    font-size: 18px;
    padding-top: 0.3rem;
}

.input{
    position: relative;
    width: 100%;
    height: 50px;
    margin-bottom: 0.7rem;
}

.input input{
    width: 100%;
    height: 50px;
    padding-top: 1.1rem;
    padding-left: 9px;
    outline: none;
    border: 1px solid #8c8c8c;
    border-radius: 3px;
    transition: 0.2s;
}

.input label{
    position: absolute;
    top: 0%;
    left: 10px;
    /* font-size: 1.1rem; */
    font-size: 0.9rem;
    color: #8c8c8c;
    transition: 0.2s;
}

.input input:focus ~ label, .input input:valid ~ label{
    top: 10%;
    font-size: 0.8rem;
    color: #000;
} 

.input input:focus{
    border-width: 2px;
    border-color: #0A66C3;
}

.input :disabled{
    background-color: unset;
}

.dateSelect{
    background-color: unset;
}

.forgot-pass-link{
    width: 140px;
    padding: 0.2rem;
    text-align: center;
    text-decoration: none;
    font-weight: bolder;
    color: #0A66C3;
    transition: 0.3s;
    border-radius: 12px;
}

.forgot-pass-link:hover{
    background: rgba(10, 102, 195, 0.3);
}

button{
    height: 50px;
    background: #0A66C3;
    outline: none;
    border: none;
    border-radius: 30px;
    color: #fff;
    font-size: 1rem;
    font-weight: bolder;
}

.join-link{
    text-align: center;
}

.join-link a{
    text-decoration: none;
    font-weight: bolder;
    color: #0A66C3;
}

.modalHeader {
    height: 51px !important;
    background-color: #ee7f00 !important;
    color: white !important;
    font-size: 20px !important;
    line-height: 18px !important;
}

.warn{
    font-weight: bold;
    font-size: 25px;
    color: #ee7f00;
}

.thankyou{
    font-weight: bold;
    font-size: 16px;
    color: black;
}

.fixedLabel{
    font-weight: bold;
}

.thankyouHelp{
    margin-top: 10px;
    color: #4285F4;
    text-align: justify;
    font-size: 10px;
}

.telNum{
    text-decoration: none;
    font-weight: bold;
    text-align: justify;
    font-size: 10px;
}